<template>
  <div>
    <div class="total-point">
      <b-row>
        <b-col>
          <div>
            <font-awesome-icon :icon="['fas', 'gift']" />
            <span class="ml-2">
              <div class="pd-3px color-gray" style="margin-top: -4%">
                คะแนนปัจจุบัน
              </div>
            </span>
          </div>
        </b-col>
        <b-col class="text-right">
          <span class="point-count">{{ total | numeral("0,0") }} คะแนน</span>
        </b-col>
      </b-row>
    </div>
    <div class="content-point p-3 my-3" v-if="!isLoading">
      <b-row>
        <b-col class="point-date">จำนวนที่ได้รับ/ใช้งาน</b-col>
        <b-col class="text-right point-date">คะแนนสะสมทั้งหมด</b-col>
      </b-row>
      <div v-if="list.length > 0">
        <div
          class="py-2 my-2 point-list"
          v-for="(item, index) in list"
          :key="index"
        >
          <b-row class="mx-1">
            <b-col>
              <div v-if="Math.sign(item.point) == -1">
                <span class="earn_point">{{
                  item.point | numeral("0,0")
                }}</span>
              </div>
              <div v-else>
                <span class="point">+{{ item.point | numeral("0,0") }}</span>
              </div>
              <div class="ft-14 color-gray" v-if="item.created_time">
                {{ dateFormat(item.created_time, "lll") }}
              </div>
              <div class="ft-14 color-gray">-</div>
            </b-col>
            <b-col class="text-right">
              <div class="point-time">
                {{ item.current_point | numeral("0,0") }}
              </div>
              <div class="ft-14 color-gray">
                {{ item.branch_name ? item.branch_name : "-" }}
              </div>
            </b-col>
          </b-row>
          <div v-if="item.product.length > 0">
            <div class="text-center">
              <b-button
                @click.prevent="hide(index)"
                v-b-toggle="'accordion-productdetails' + index"
              >
                <font-awesome-icon
                  icon="chevron-down"
                  class="color-gray"
                  v-if="item.show"
                />
              </b-button>
            </div>
            <b-collapse
              :id="'accordion-productdetails' + index"
              ref="collapse"
              class="mt-2"
            >
              <hr />
              <b-row
                class="mx-1 ft-14 color-gray"
                v-for="(product, i) in item.product"
                :key="i"
              >
                <b-col cols="9" class="line-clamp-2">{{ product.name }}</b-col>
                <b-col cols="3" class="text-right">
                  <div>{{ product.quantity }}</div>
                  <div>
                    {{ (product.price * product.quantity) | numeral("0,0.00") }}
                  </div>
                </b-col>
              </b-row>
              <hr class="my-2" />
              <b-row class="mx-2">
                <b-col class="ft-14 color-gray point-date" cols="9"
                  >{{ item.product.length }} รายการ</b-col
                >
                <b-col
                  class="text-right ft-14 color-gray point-date"
                  cols="3"
                  >{{ item.total | numeral("0,0.00") }}</b-col
                >
              </b-row>
              <div class="text-center">
                <b-button
                  v-b-toggle="'accordion-productdetails' + index"
                  @click.prevent="show(index)"
                >
                  <font-awesome-icon icon="chevron-up" class="color-gray" />
                </b-button>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="notfound-data">ไม่พบข้อมูล</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    list: {
      required: true,
      type: Array
    },
    total: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      isLoading: true
    };
  },
  created() {
    for (const item of this.list) {
      item.show = true;
    }
    this.isLoading = false;
  },
  methods: {
    dateFormat(value, format) {
      if (!value) return;
      if (!format) format = "DD-MM-YYYY HH:mm";
      return moment(value).locale("th").format(format).replace("เวลา", "");
    },
    hide(index) {
      this.isLoading = true;
      this.list[index].show = false;
      this.isLoading = false;
    },
    show(index) {
      this.isLoading = true;
      this.list[index].show = true;
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.total-point {
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  padding: 10px;
}
.point-count {
  font-size: 17px;
  font-weight: 600;
}
.pd-3px {
  padding: 0 25px;
  font-size: 14px;
}
.content-point {
  background-color: #fff;
  border-radius: 5px;
}
.point {
  color: #76ad73;
  font-weight: 600;
}
.earn_point {
  color: red;
  font-weight: 600;
}
.point-list {
  background-color: #e5e5e5;
  border-radius: 5px;
  margin: 0;
}
.ft-14 {
  font-size: 14px;
}
.point-time {
  font-weight: 700;
}
.color-gray {
  color: #747474;
}
.btn-secondary {
  background-color: transparent;
  border: none;
}
.point-date {
  font-weight: bold;
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  background-color: transparent;
  box-shadow: none;
}
::v-deep .btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none;
}
::v-deep hr {
  margin-top: 0;
  margin-bottom: 1rem;
}
::v-deep .btn {
  padding: 0;
}
.line-clamp-2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.notfound-data {
  color: #000;
  text-align: center;
  padding: 50px 0;
}
</style>
