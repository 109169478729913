<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else>
      <div class="point-content">
        <PointLogComponent :list="pointLog" :total="totalPoint" />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb.vue";
import PointLogComponent from "../../../components/point/PointLogComponent.vue";
import OtherLoading from "@/components/other/OtherLoading";
export default {
  components: {
    Breadcrumb,
    PointLogComponent,
    OtherLoading
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "ประวัติการใช้คะแนน",
          to: "/profile"
        }
      ],
      pointLog: [],
      totalPoint: 0,
      isLoading: true,
      requestData: { BranchId: 0 }
    };
  },
  async created() {
    await this.getPointHistoryLog();
  },
  methods: {
    async getPointHistoryLog() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/user/PointLogAllBranch`)
        .then(data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.pointLog = data.detail.point_log;
            this.totalPoint = data.detail.last_point;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.content-page {
  //   margin-top: 80px;
  min-height: 100vh;
}
.point-content {
  margin: 1rem;
}
</style>
